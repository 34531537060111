<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="langunitMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
            <feather-icon icon="SkipBackIcon" /> {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group :label="$t('Language Unit Master List.LANGUAGE UNIT NAME')"> 
              <validation-provider
                #default="{ errors }"
                name="Language Unit Name"
                
                rules="required"
              >
                <b-form-input
                  v-model="form.langunit_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Language Unit Master List.LANGUAGE UNIT NAME')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Language Unit Master List.LANGUAGE UNIT CODE')">
              <validation-provider
                #default="{ errors }"
                name="Language Unit Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.langunit_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Language Unit Master List.LANGUAGE UNIT CODE')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Language Unit Master List.Language Name')">
              <validation-provider
                #default="{ errors }"
                name="Language Name"
                rules="required"
              >
                <v-select
                  :reduce="(language) => language.language_id"
                  label="language_name"
                  :placeholder="$t('Language Unit Master List.Language Name')"
                  :disabled="!$can('write', 'masters')"
                  v-model="form.langunit_lang_id"
                  :state="errors.length > 0 ? false : null"
                  :options="$store.getters['masters/getAllLanguage']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('Language Unit Master List.Language Unit Description')">
              <validation-provider
                #default="{ errors }"
                name="Language Unit Description"
                rules=""
              >
                <b-form-textarea
                  v-model="form.langunit_desc"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Language Unit Master List.Language Unit Description')"
                  :disabled="!$can('write', 'masters')"
                  rows="3"
                /> 
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider> 
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="saveLangunit"
            > 
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    vSelect,
  },
  data() {
    return {
      langLists: [],
      langunit_id: null,
      form: {
        langunit_name: "",
        langunit_code: "",
        langunit_lang_id: "",
        langunit_desc: "",
        langunit_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveLangunit() {
      this.$refs.langunitMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.langunit_id = this.langunit_id;
            // console.log("this.form=>",this.form);return false;
            const langunitMaster = await MasterServices.saveLangunit(this.form);

            if (langunitMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    langunitMaster.data.message ||
                    "Language Unit Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/masters/langunit-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    langunitMaster.data.message || "Cannot Added Language Unit",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveLanguage Unit ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveLanguage Unit",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleLangunit(langunit_id) {
      try {
        const response = await MasterServices.getSingleLangunit(langunit_id);
        let singleLanguageUnit = response.data.data;
        if (response.data.status) {
          this.form = {
            langunit_name: singleLanguageUnit.langunit_name,
            langunit_code: singleLanguageUnit.langunit_code,
            langunit_lang_id: singleLanguageUnit.langunit_lang_id,
            langunit_desc: singleLanguageUnit.langunit_desc,
            langunit_is_active: singleLanguageUnit.langunit_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    this.$store.dispatch("masters/setLanguage");
    if (
      this.$route.params.langunit_id &&
      !isNaN(this.$route.params.langunit_id)
    ) {
      this.langunit_id = this.$route.params.langunit_id;
      this.getSingleLangunit(this.langunit_id);
    }
  },
};
</script>

<style></style>
